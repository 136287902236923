import React from 'react'

export default () => {
  const [msg, setMsg] = React.useState('')
  const timeoutId = React.useRef()

  const setMessage = React.useCallback((newMsg, timeout) => {
    setMsg(newMsg)
    timeoutId.current && clearTimeout(timeoutId.current)
    timeoutId.current = null

    if (newMsg && timeout) {
      timeoutId.current = setTimeout(() => {
        setMsg('')
        timeoutId.current = null
      }, timeout)
    }
  }, [])

  return [msg, setMessage]
}
