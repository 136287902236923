import React, { useCallback } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Form, Grid, Message, Progress, Button } from 'semantic-ui-react'
// import ReCAPTCHA from 'react-google-recaptcha'

import TextInput from './TextInput'

// contact services
import { submitLandingPageForm } from '../services/bullarum.service'

import useTimeoutMessage from '../hooks/useTimeoutMessage'

// define contact us schema for validation
const ContactSchema = Yup.object().shape({
  customerName: Yup.string()
    .required('Your name is required')
    .min(2, 'Invalid input')
    .max(25, 'Invalid input')
    .trim(),
  phoneNumber: Yup.string()
    .matches(/[0-9]{10}$/, 'Invalid input')
    .max(10, 'Invalid input')
    .required('Please enter your phone number!!!')
})

const DEFAULT_VALUES = {
  customerName: '',
  phoneNumber: ''
}

export default ({ onSuccess }) => {
  // const recaptchaRef = createRef()

  const [errorMessage, setErrorMessage] = useTimeoutMessage('')
  const [successMessage, setSuccessMessage] = useTimeoutMessage('')

  // const executeRecaptcha = () => {
  //   recaptchaRef.current.execute()
  // }

  // const handleExpired = () => {}

  const handleSubmission = useCallback(
    (values, actions) => {
      actions.setSubmitting(true)
      const { customerName, phoneNumber } = values

      // sanitize data before sending
      const sanitizedData = {
        customerName: _.escape(_.trim(customerName)),
        phoneNumber: _.escape(_.trim(phoneNumber)),
        status: 'new'
      }

      // submitting form value
      submitLandingPageForm(sanitizedData)
        .then(res => {
          setSuccessMessage(res.data.message)
          onSuccess && onSuccess()
        })
        .catch(error => {
          if (error.response) {
            setErrorMessage(error.response.data, 5000)
          } else {
            setErrorMessage('Something wrong happened. Please try again later!!!', 5000)
          }
        })
        .finally(() => {
          actions.setSubmitting(false)
          actions.resetForm()
        })
    },
    [onSuccess, setErrorMessage, setSuccessMessage]
  )

  return (
    <Formik
      initialValues={DEFAULT_VALUES}
      onSubmit={handleSubmission}
      validationSchema={ContactSchema}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <>
          <Form noValidate className="attached fluid segment rounded shadow-sm">
            {isSubmitting && <Progress percent={100} attached="top" active size="medium" />}
            {successMessage === '' && (
              <Grid stackable padded>
                <Grid.Row columns="2">
                  <Grid.Column>
                    <TextInput
                      name="customerName"
                      label="Họ Tên"
                      placeholder="Vui lòng điền họ tên"
                      autoComplete="name"
                      required
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <TextInput
                      name="phoneNumber"
                      label="Số Điện Thoại"
                      placeholder="Vui lòng điền số điện thoại"
                      required
                    />
                  </Grid.Column>
                </Grid.Row>

                {/* {isValid && (
                  <Grid.Row>
                    <Grid.Column>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6Le0asMUAAAAALU8Bsu2PvNdUevkl9mM4s8YmT7F"
                        onChange={handleSubmit}
                        onExpired={handleExpired}
                        badge="inline"
                      />
                    </Grid.Column>
                  </Grid.Row>
                )} */}

                <Grid.Row>
                  <Grid.Column>
                    <h4>Anh chị có đồng ý cho chúng tôi liên lạc để tư vấn thêm không?</h4>
                    <Button
                      type="submit"
                      primary
                      onClick={handleSubmit}
                      disabled={isSubmitting || !isValid}
                    >
                      Đồng Ý
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Form>

          {errorMessage !== '' && (
            <Message
              attached="bottom"
              negative
              content="Xin lỗi hiện tại hệ thống đang gặp vấn đề trục trặc kỹ thuật. Quý anh chị vui lòng liên hệ trực tiếp với chúng tôi (714) 895-8865!!!"
            />
          )}

          {successMessage !== '' && (
            <Message attached="bottom" positive>
              <h1>THANK YOU!!!</h1>
              <p>Nhân viên tư vấn sẽ liên lạc với quý anh chị sớm.</p>
            </Message>
          )}

          <div className="row bg-light mt-4">
            <div className="col px-0">
              <div className="d-flex flex-wrap px-2 py-2 justify-content-center">
                <a href="https://www.contegospa.com/privacy">Privacy Policy</a>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  )
}
