import React, { useCallback, useState } from 'react'
import { navigate } from 'gatsby'
import { Button, Icon, Modal } from 'semantic-ui-react'
import SwiperCore, { Pagination, Mousewheel, A11y, Autoplay } from 'swiper'

// Import Swiper styles
// import 'swiper/swiper.scss'
// import 'swiper/components/pagination/pagination.scss'

import SEO from '../../components/SEO'
import LandingPageLeadForm from '../../components/LandingPageLeadForm'
import Vimeo from '../../components/Vimeo'

import Logo from '../../images/logos/contego-logo.png'

import { callHandler } from '../../ultilities'

SwiperCore.use([Pagination, A11y, Mousewheel, Autoplay])

const VIDEOS = [
  {
    // title: 'Hãy cùng nghe những lời chia sẻ đó',
    id: 494597824,
    frameRatio: 9 / 16
  },
  { title: 'Ghế spa Contego đẹp như thế nào trong tiệm nails?', id: 494599143, frameRatio: 9 / 16 }
]

const ClientBenefits = () => {
  const handleCallNow = e => {
    const currentHour = Number(
      new Date()
        .toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour12: false })
        .split(':')[0]
    )

    if (currentHour >= 8 && currentHour <= 21) {
      callHandler(e.currentTarget.dataset.phoneNumber)
      navigate('/vi/thank-you')
    } else {
      // eslint-disable-next-line no-alert
      window.alert(
        'Liên lạc ngoài giờ làm việc!!! Anh chị vui lòng để lại thông tin bên dưới, nhân viên tư vấn sẽ liên lạc. Giờ làm việc của nhân viên tư vấn 8AM-9PM (Giờ California)'
      )
    }
  }

  const handleSuccess = useCallback(() => {
    navigate('/vi/thank-you')
  }, [])

  return (
    <>
      <div className="container">
        <SEO
          title="CẢM NHẬN KHÁCH VỀ GHẾ CONTÉGO?"
          description="Vì sao đầu tư ghế pedicure spa massage chair của CONTEGO là tốt nhất."
          meta={[
            {
              name: 'keywords',
              content: 'GIÁ TRỊ ghế Contégo, lợi ích ghế Contego, hữu ích, lợi ích, ghế Contego'
            }
          ]}
        />
        <div className="row">
          <div className="col py-4 ld-header shadow-sm">
            <img src={Logo} width="100px" height="auto" alt="contego company logo" />

            <h4 className="my-auto ml-4 text-center">CẢM NHẬN KHÁCH HÀNG VỀ GHẾ CONTÉGO</h4>
          </div>
        </div>
        <div className="row">
          <div className="col px-0">
            <div className="bg-periwinkle py-2">
              <h2 className="text-secondary text-center px-4 py-2">
                <sup>
                  <Icon size="small" name="quote left" />
                </sup>
                It&apos;s very important now that I know this. I will be coming back because of the
                Contego disposable jets.
                <sup>
                  <Icon size="small" name="quote right" />
                </sup>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col py-4">
            <h2 className="py-4 my-4">
              Không những khách công nhận về sự an toàn vệ sinh khi trải nghiệm dịch vụ trên ghế
              Contégo mà chủ tiệm cũng rất yêu thích những tính năng hữu ích của ghế Contégo.
            </h2>
          </div>
        </div>

        {VIDEOS.map(video => {
          return (
            <div className="row">
              <div className="col">
                {video.title && <h2 className="py-4 my-4">{video.title}</h2>}

                <Vimeo
                  id={video.id}
                  title={video.title}
                  frameRatio={video.frameRatio}
                  threshold={[0, 1]}
                />
              </div>
            </div>
          )
        })}

        <div className="row bg-light my-4 rounded shadow">
          <div className="col py-4">
            <h2>
              Khi khách hiểu sự quan tâm về an toàn vệ sinh cho họ, họ sẽ luôn luôn quay lại. Đây là
              lợi thế canh tranh của tiệm khi sữ dụng Contego Jetliner. <br /> <br />
              <strong className="text-blue">
                Để hiểu thêm về các lợi thế cạnh tranh khác, mẫu mã và giá, hãy{' '}
              </strong>
              <Button
                className="call-now-btn rounded-pill text-white shadow-sm"
                data-phone-number="7148958865"
                onClick={handleCallNow}
              >
                GỌI NGAY <Icon name="phone" className="m-0" />
              </Button>{' '}
              <strong className="text-blue">Hoặc để lại thông tin liên lạc bên dưới.</strong>
            </h2>

            <LandingPageLeadForm onSuccess={handleSuccess} />
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <MobileNativeNav />
      </div>
    </>
  )
}

const MobileNativeNav = ({ onClose }) => {
  const [isShown, setIsShown] = useState(false)

  const handleCallNow = e => {
    const currentHour = Number(
      new Date()
        .toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour12: false })
        .split(':')[0]
    )

    if (currentHour >= 8 && currentHour <= 21) {
      callHandler(e.currentTarget.dataset.phoneNumber)
      navigate('/vi/thank-you')
    } else {
      // eslint-disable-next-line no-alert
      window.alert(
        'Liên lạc ngoài giờ làm việc!!! Anh chị vui lòng để lại thông tin bên dưới, nhân viên tư vấn sẽ liên lạc. Giờ làm việc của nhân viên tư vấn 8AM-9PM (Giờ California)'
      )
    }
  }

  const handleSuccess = useCallback(() => {
    navigate('/vi/thank-you')
  }, [])

  const toggleViewForm = useCallback(() => {
    setIsShown(isShown => !isShown)
  }, [])

  const handleCloseLeadForm = useCallback(() => {
    setIsShown(false)
    onClose && onClose()
  }, [onClose])

  return (
    <div className="mobile-native-nav">
      <div className="px-2 py-2 d-flex justify-content-between align-items-center shadow">
        <Button onClick={toggleViewForm} className="my-2 rounded-pill shadow-sm" primary>
          LIÊN LẠC TÔI
        </Button>

        <Button
          className="call-now-btn rounded-pill text-white shadow-sm"
          data-phone-number="7148958865"
          onClick={handleCallNow}
        >
          GỌI NGAY <Icon name="phone" className="m-0" />
        </Button>
      </div>

      <Modal open={isShown}>
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <img src={Logo} alt="contego logo" className="d-block" width="180px" height="auto" />
            <Button icon color="red" onClick={handleCloseLeadForm}>
              <Icon name="close" className="text-white" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content>
          <LandingPageLeadForm onSuccess={handleSuccess} />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default ClientBenefits
